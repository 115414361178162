<script lang="ts">
import { useUrlSearchParams } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import Ierror from '@/assets/icons/payment-result-error.svg'
import Isuccess from '@/assets/icons/payment-result-success.svg'
import Iwarning from '@/assets/icons/payment-result-warning.svg'
import Copy from '@/components/copy'
import { useTgCustomer } from '@/hooks/useTgCustomer'

interface IPayStatsuType {
  icon: string
  title: () => string
  status: number[]
}

const paystatus: IPayStatsuType[] = [
  {
    icon: Isuccess,
    title: () => $t('rymcaR2pCGZMIIFKt9rdX'),
    status: [4, 5, 6, 15],
  },
  {
    icon: Iwarning,
    title: () => $t('7pGTq2B-DWePfga8XN3S3'),
    status: [3],
  },
  {
    icon: Iwarning,
    title: () => $t('NjWFSDt0-XjSJwd5Lt1KQ'),
    status: [0],
  },
]
const errorStatus: IPayStatsuType[] = [
  {
    icon: Ierror,
    title: () => $t('-sBqqGY9_vH0DnctycEru'),
    status: [17025],
  },
  {
    icon: Ierror,
    title: () => $t('giGjjb9__P0rXIdOFk0xc'),
    status: [12002],
  },
]
</script>

<script setup lang="ts">
const tgLink = useTgCustomer()
const { out_trade_no } = useUrlSearchParams()
const state = ref<IPayStatsuType | undefined>(undefined)

const resultQuerier = useQuery({
  enabled: !!out_trade_no,
  refetchInterval: toRef(() => state.value?.status.includes(3) ? 3 * 1000 : false),
  refetchOnWindowFocus: toRef(() => !!state.value?.status.includes(3)),
  queryKey: [apis.orderQuery.id, out_trade_no],
  queryFn: () => apis.orderQuery({ order_no: String(out_trade_no) }, { errorMessageMode: 'none' }),
  retry(failureCount, error) {
    if (error instanceof ApiError)
      return ![4, 5, 6, 15, 3, 0, 17025, 12002].includes(error?.code as number)

    return failureCount < 2
  },
})

watch(resultQuerier.data, (data) => {
  if (data) {
    state.value = paystatus.find((i) => {
      return i.status.includes(data.status)
    })
    state.value = state.value ?? paystatus[2]
  }
}, { immediate: true })

watch(resultQuerier.error, (error) => {
  if (error instanceof ApiError) {
    // 如果有其他状态就有点危险了
    state.value = errorStatus.find((i) => {
      return i.status.includes(error?.code as number)
    })
  }
}, { immediate: true })
</script>

<template>
  <div class="page-cotnent page-content-width">
    <!-- 没有 out_trade_no 不显示任何东西 -->
    <ASpin v-if="out_trade_no" :spinning="resultQuerier.isLoading.value">
      <div v-if="state" class="main">
        <img class="result-icon" :src="state?.icon ?? ''">
        <div class="result-title">{{ state?.title() ?? $t('-MjYAqdGMw1Jpq1bk-qXy') }}</div>
        <div class="tips">
          {{ $t('dvgrJlLvYhuh9wK71lCpS') }} <a data-test-id="uw9C" :href="tgLink" target="_blank" class="decoration-underline">{{ $t("AdXmjYoBblaVXYOkEC_jF") }}</a>
        </div>
        <div class="order-info">
          <span>{{ $t("xXZyGdLcrY-t3CtLl6z95") }}：</span><wbr><span>{{ out_trade_no }}</span>
          <Copy :content="String(out_trade_no)" />
        </div>
      </div>
    </ASpin>
  </div>
</template>

<style scoped lang="scss">
.page-cotnent {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 800px;
  padding: 30px;
  margin: 15px auto;
  background: linear-gradient(180deg, rgb(44 57 103 / 100%) 0%, rgb(32 44 82 / 100%) 8%, rgb(32 44 82 / 100%) 29%);
  border-radius: 30px;

  @media bp-lt-tabletl {
    min-height: 500px;
    padding: 30px 15px;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: theme('colors.primary.100');
  }

  .result-icon {
    width: 160px;
    height: auto;

    @media bp-lt-tabletl {
      width: 140px;
    }
  }

  .result-title {
    padding: 20px 0;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .tips {
    font-size: 1rem;
    line-height: 1.2em;
    color: #8098bf;
  }

  .order-info {
    padding: 1em 1.5em;
    margin-top: 35px;
    font-size: 1rem;
    line-height: 1.4em;
    color: theme('colors.primary.100');
    text-align: center;
    white-space: nowrap;
    background-color: theme('colors.bg.deep');
    border-radius: 6px;
  }
}
</style>
